import * as FullStory from "@fullstory/browser";
import { Analytics } from "@vercel/analytics/react";
import * as Fathom from "fathom-client";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import { useEffect } from "react";
import "../styles/globals.css";

const FathomTrackingCode = process.env.NEXT_PUBLIC_FATHOM_TRACKING_CODE || "";

export default function App({ Component, pageProps }: AppProps) {
	const router = useRouter();
	useEffect(() => {
		FullStory.init({
			orgId: process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID || "",
			devMode: process.env.NEXT_PUBLIC_FULLSTORY_DEV_MODE === "1" ? true : false
		});
		Fathom.load(FathomTrackingCode);

		function onRouteChangeComplete() {
			Fathom.trackPageview();
		}

		router.events.on("routeChangeComplete", onRouteChangeComplete);
		return () => {
			router.events.off("routeChangeComplete", onRouteChangeComplete);
		};
	}, [router.events]);

	return (
		<>
			<Component {...pageProps} />
			<Analytics />
		</>
	);
}
